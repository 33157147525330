import { labels } from "@/helpers/labels";

/** IMPORTANT NOTE: also add default values to `defaultStates.js` for
 *  state properties to be defined!!
 */

export default {
  socketURL: "http://192.168.1.9:3011",
  totalCartLength: 0,
  storeSearch: "",
  // uploaded to server
  storeFiles: [],
  // base64 images for client side
  storeUploads: [],

  global: true,
  modelSettlment: undefined,
  income: undefined,
  online: undefined,
  locale: "en",
  labels,
  selectedStudio: "",
  selected_user: null,
  localStudios: [],
  user: null,
  userAccountTypes: ["Model", "Trainer", "Manager"],
  // TODO(madalin): Get studio configured currencies
  currencies: ["usd", "eur", "ron", "cop"],
  appUrl: "",
  // Accounts state --------------------------------------
  // These are the active app users
  appUsers: [],
  // These are the **inactive** app users
  // These start off as **null** as they may not be loaded,
  // and we want to differentiate between empty inactive users and not loaded.
  inactiveUsers: null,
  loadInactiveUsers: false,
  // Toggle between active and inactive users.
  showOnlyActiveUsers: true,
  /** Search accounts by their username and account type -> Accounts */
  searchUser: "",
  accountTypeUser: "",
  accountUserLocation: "",
  // End accounts state -----------------------------------
  websiteOptions: [],
  settings: {},
  toggleViews: {
    shift: false,
    statistics: false,
  },
  exchangeRateUsdToRon: 4.6,
  exchangeRateUsdToEur: 0.92,
  initialAvailableBudget: { currency: "usd", budget: 400 },
  scheduleResponse: [],
};
