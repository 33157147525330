import { labels, helpText } from "@/helpers/labels";

let flag_ = require('./assets/metronic/theme/default/src/assets/media/flags/020-flag.svg');
let user_key='da85532cc5fe082d8d6ea3c09c353007';

const getDefaultState = () => {
    return {
        decrypt(data) {
            const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
            const test = base64regex.test(data);

			if (typeof data !== 'string' || !test) return data;

			let ret = '';
			data = atob(data);
			for (let i = 0; i < data.length; i++) {
				ret += String.fromCharCode(
				data.charCodeAt(i) ^ user_key.charCodeAt(i % user_key.length)
				);
			}
			return ret;
        },
        exp_user: {
            "suport_orar": "STUMA Support",
            "superman": "Cristian",
            "test_support": "Live App Support",
            "andy": "andy"
        },
        viewers:[],
        lovense:{
            user:'',
            pass:''
        },
        // Moved into modules/shift state
        session:{
            state:0,
            times: undefined
        },
        ext_id:'leakfhamgbdfnkofmapnjjmpffemcaab',
        locale: 'en',
        labels,
        helpText,
        curLanguage: JSON.parse(localStorage.getItem('selectedLanguage')) != null ? JSON.parse(localStorage.getItem('selectedLanguage')).lang : 'en',
        langPicSrc: JSON.parse(localStorage.getItem('selectedLanguage')) != null ? JSON.parse(localStorage.getItem('selectedLanguage')).src : flag_,
        languages: [
            {
            short: 'en',
            long: 'English'
            },
            {
            short: 'de',
            long: 'German'
            }
        ],
        new_user:{
            "active" : true,
            "culoarea" : "",
            "cazare" : false,
            "name" : "",
            "surname" : "",
            "email" : "",
            "user" : "",
            "pass" : "",
            "adresa" : "",
            "oras" : "",
            "telefon" : "",
            "varsta" : {
                "ziua" : "",
                "luna" : "",
                "anul" : ""
            },
            "tara" : "",
            "tipCont" : "Model",
            "ci" : {
                "cnp" : "",
                "serie" : "",
                "luna" : "",
                "anul" : ""
            },
            "zipCode" : "",
            "studioul" : "",
            "date" : "",
            "auto_pause" : false,
            "iban" : "",
            "privilegii" : false,
            "locker" : "",
            "media" : {
                "galleries" : [

                ],
                "avatar" : [
                ],
                "social" : [
                ],
                "erotic" : [],
                "glamour" : [],
                "admin" : []
            }
        },
        // Accounts state --------------------------------------
        // These are the active app users
        appUsers:[],
        // These are the **inactive** app users
        // These start off as **null** as they may not be loaded,
        // and we want to differentiate between empty inactive users and not loaded.
        inactiveUsers: null,
        loadInactiveUsers: false,
        // Toggle between active and inactive users.
        showOnlyActiveUsers: true,
        // End accounts state -----------------------------------
        isChat:false,
        emailUsers:[],
        chatUsers:[],
        // appUrl: /https?:\/\/localhost:3081/g.test(window.location.href) ? 'http://localhost:3081/api/' : '/',
        appUrl: /https?:\/\/localhost:8080/g.test(window.location.href) ? 'http://localhost:8080/api/' : '/',
        selectedStudio: '',
        localStudios: [],
        userAccountTypes: ['Model', 'Trainer', 'Manager'],
        currencies: ['usd', 'eur', 'ron', 'cop'],
        chatUrl: '',
        global: true,
        page_title:'Dashboard',
        page_icon:'m-nav__link-icon la la-home',
        page_subsection:'',
        page_section: '',
        selected_user:{
            ci:{
                serie:'',
                cnp:''
            }
        },
        user:{
            ci:{
                serie:'',
                cnp:''
            }
        },
        mail:{
            title:'default title',
            id:0,
            sender:'no sender',
            content:'content',
            to:['none', 'doi']
        },
        groups:[],
        holidays:[],
        isGallery: false,
        usage:{},
        sites:[],
        email_note:0,
        emails_note_total:0,
        chat_note:false,
        notifications_note_total:2,
        selected_media:[],
        widget_media:[],
        media_tags:[],
        media_test_tags:[],
        user_studio:'',
        vertical_menu: {dashboard:{
            route:'/',
            name:'Dashboard',
            onclick:null,
            id:'dash_id'
        }, sections:[{name:'',links:[]}]},
        horizontal:{},
        settings:{},
        users:[],
        websiteOptions: [
            { value: "adw", label: "AdultWork"},
            { value: "bim", label: "BimBim"},
            { value: "bng", label: "BongaCams"},
            { value: "cam", label: "Cams"},
            { value: "cgf", label: "ChatGF"},
            { value: "cm4", label: "Cam4"},
            { value: "cmc", label: "CamContacts"},
            { value: "cmd", label: "CamDolls"},
            { value: "cmg", label: "Camgams"},
            { value: "cmp", label: "Camplace"},
            { value: "cms", label: "Camsoda"},
            { value: "ctb", label: "Chaturbate"},
            { value: "dmc", label: "Dreamcam"},
            { value: "elv", label: "EuroLive"},
            { value: "exc", label: "Extasy"},
            { value: "f4f", label: "Flirt4Free"},
            { value: "iml", label: "Imlive"},
            { value: "ljm", label: "LiveJasmin"},
            { value: "mfa", label: "MFCAlerts"},
            { value: "mfc", label: "MyFreeCams"},
            { value: "nkd", label: "Naked"},
            { value: "stm", label: "Streamate"},
            { value: "str", label: "Stripchat"},
            { value: "xcm", label: "XCams"},
            { value: "xlc", label: "Xlove"},
            { value: "xmd", label: "Xmodels"},
        ],
        toggleViews: {
            shift: true,
            statistics: true,
        },
    }
  }

  export default getDefaultState